import { isCanceledRequestError, isNetworkError, isRequestError } from '@services/common/request';
import apmService from '@services/common/apm';
import getConfig from 'next/config';

function convertToError(error: unknown): Error {
  try {
    return new Error(JSON.stringify(error));
  } catch {
    // фэлбэк на случай, если stringify упадёт с ошибкой,
    // например, при circular references
    return new Error(String(error));
  }
}

function getResolvedError(error: unknown) {
  if (isRequestError(error)) {
    return error.error;
  }

  if (error instanceof Error) {
    return error;
  }

  return convertToError(error);
}

export default function logError(error: unknown): void {
  const isProd = getConfig()?.publicRuntimeConfig?.IS_PROD;

  if (isCanceledRequestError(error) || isNetworkError(error)) {
    if (!isProd) {
      console.log('Нелогируемая ошибка: ', error);
    }

    return;
  }

  const resolvedError = getResolvedError(error);
  apmService.setError({ error: resolvedError });

  if (!isProd) {
    console.log('Логируемая ошибка: ', resolvedError);
  }
}

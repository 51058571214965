import { ReactNode, createContext, useContext } from 'react';

import useCart, { TCartDispatch } from './useCart';
import { TCartState } from '@entities/cart';

const CartStateContext = createContext({} as TCartState);
const CartDispatchContext = createContext({} as TCartDispatch);

export const CartProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, dispatch] = useCart();

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>{children}</CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
};

export function useCartState() {
  const context = useContext(CartStateContext);

  if (context === undefined) {
    throw new Error('useCartState must be used within a CartProvider');
  }

  return context;
}

export function useCartDispatch() {
  const context = useContext(CartDispatchContext);

  if (context === undefined) {
    throw new Error('useCartDispatch must be used within a CartProvider');
  }

  return context;
}

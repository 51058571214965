
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AppContext, AppProps } from 'next/app';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import Authorization from '@components/Authorization';
import ErrorBoundary from '@components/ErrorBoundary';
import GlobalClientError from '@components/GlobalClientError';
import GoogleTag from '@components/GoogleTag';
import Head from 'next/head';
import NotificationsWithLogic from '@components/NotificationsWithLogic';
import NProgressBar from '@components/NProgressBar';
import TestClientErrorOnStaging from '@components/TestClientErrorOnStaging';
import 'emex-ui-kit/dist/index.css';
import '@styles/index.scss';
import { AppProvider, fetchAppData } from '@store/app';
import { AuthProvider, getUserDetails } from '@store/auth';
import { CartProvider } from '@store/cart';
import { NotificationsProvider } from '@store/notifications';
import { APM_OPTIONS } from '@entities/apm';
import { COUNTRY_KEY } from '@constants/countries';
import apmService from '@services/common/apm';
import browserGuard from '@shared/browserGuard';
import cleanUrl from '@shared/cleanUrl';
import serverStorage from '@shared/serverStorage';
import testServerErrorOnStaging from '@shared/testServerErrorOnStaging';
const MyAppContent = ({ Component, pageProps }: AppProps): JSX.Element => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false
            }
        }
    });
    const router = useRouter();
    const { country, domains, isNeedConfirmCountry, isUserAuthorized, locale, userName, ...otherProps } = pageProps;
    useEffect(() => {
        // если пришли с другого домена, скидываем информацию о выбранной стране
        if (router.query[COUNTRY_KEY]) {
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.delete(COUNTRY_KEY);
            router.push(router.pathname, { pathname: cleanUrl(router.asPath), query: newUrl.searchParams.toString() }, { shallow: true });
        }
        if (APM_OPTIONS.active) {
            apmService.init({ options: APM_OPTIONS });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
      <Head>
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/appleTouchIcon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon16x16.png"/>
      </Head>

      <QueryClientProvider client={queryClient}>
        <NotificationsProvider>
          <AppProvider value={{ country, domains, isNeedConfirmCountry, locale }}>
            <AuthProvider value={{ isUserAuthorized, userName }}>
              <CartProvider>
                <Component {...otherProps}/>
              </CartProvider>
              <Authorization />
            </AuthProvider>
          </AppProvider>
          <NotificationsWithLogic />
        </NotificationsProvider>
      </QueryClientProvider>

      <NProgressBar />
      <GoogleTag />

      <div id="modal-root" style={{ position: 'fixed', zIndex: 10000 }}/>
      <div id="notifications-root" style={{ position: 'fixed', zIndex: 99999 }}/>
    </>);
};
const MyApp = (props: AppProps): JSX.Element => (<ErrorBoundary errorContent={<GlobalClientError />}>
    <TestClientErrorOnStaging queryParamForThrowingError="testClientGlobalError">
      <MyAppContent {...props}/>
    </TestClientErrorOnStaging>
  </ErrorBoundary>);
MyApp.getInitialProps = async (appContext: AppContext) => {
    // проверяем, поддерживается ли браузер пользователя
    browserGuard(appContext);
    const { Component, ctx } = appContext;
    // AUX: Вызов серверной ошибки на тестовых стендов для проверки
    testServerErrorOnStaging(ctx);
    const host = (ctx.req?.headers?.host || '').split(':')[0];
    // получаем данные о доменах, странах и текущей локали
    const { country, domains, isNeedConfirmCountry, locale } = await fetchAppData(ctx);
    // запоминаем вычисленную страну и локаль в глобальном хранилище
    serverStorage.set(COUNTRY_KEY, country);
    serverStorage.set('locale', locale);
    // получаем данные о пользователе
    const { isUserAuthorized, userName } = await getUserDetails(ctx);
    // запоминаем, авторизован ли пользователь
    serverStorage.set('isUserAuthorized', isUserAuthorized);
    return {
        pageProps: {
            ...(Component.getInitialProps ? await Component.getInitialProps({ ...ctx }) : {}),
            country,
            domains,
            isNeedConfirmCountry,
            isUserAuthorized,
            locale,
            userName,
            host
        }
    };
};
const __Next_Translate__Page__18e759cc1c0__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18e759cc1c0__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  
import { ReactNode } from 'react';

import CrossInCircleIcon from '@static/icons/crossInCircle.svg';
import Empty, { TEmptyProps } from '@components/PageContents/Empty';
import useTranslation from 'next-translate/useTranslation';

export type TErrorProps = Omit<TEmptyProps, 'heading'> & {
  heading?: ReactNode;
};

const Error = ({ buttonText, description, heading, onButtonClick, ...otherProps }: TErrorProps): JSX.Element => {
  const { t } = useTranslation();

  const handleButtonClick =
    onButtonClick ??
    (() => {
      window.location.reload();
    });

  return (
    <Empty
      {...otherProps}
      buttonText={buttonText || t('components:pageContents.error.buttonRefresh')}
      description={description || t('components:pageContents.error.description')}
      heading={heading || t('components:pageContents.error.heading')}
      iconContent={<CrossInCircleIcon />}
      onButtonClick={handleButtonClick}
    />
  );
};

export default Error;

import React from 'react';
import { ButtonSize } from 'emex-ui-kit/dist/Button';
import { Title } from 'emex-ui-kit/dist/Title';
import useTranslation from 'next-translate/useTranslation';

import { Button } from '@components/NextRouteHOC';
import EmexLogoIcon from './assets/emexLogo.svg';
import LogoWrapper from '@components/LogoWrapper';
import OpenedBoxIcon from './assets/openedBox.svg';
import styles from './GlobalClientError.module.scss';

const GlobalClientError = (): JSX.Element => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    window.location.reload();
  };

  return (
    <div className={styles.component}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerMainContent}>
            <Title className={styles.title}>{t('components:globalClientError.title')}</Title>
            <div className={styles.logoWrapper}>
              <LogoWrapper>
                <EmexLogoIcon className={styles.logo} />
              </LogoWrapper>
            </div>
          </div>
          <Button className={styles.button} size={ButtonSize.BIG} onClick={handleButtonClick}>
            {t('components:globalClientError.button')}
          </Button>
        </div>
        <div className={styles.main}>
          <OpenedBoxIcon className={styles.img} />
        </div>
      </div>
    </div>
  );
};

export default GlobalClientError;

module.exports = {
  locales: ['en', 'es', 'fr'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'components'],
    '/_error': ['errorPages'],
    '/': ['home'],
    '/bulk-search': ['bulkSearch'],
    '/cart': ['cart', 'wholesaleCart', 'wholesaleCheckout'],
    '/cart/checkout': ['cart', 'checkout'],
    '/oem/[searchNum]': ['makers'],
    '/oem/[searchNum]/[urlMakerName]': ['product', 'wholesaleProduct'],
    '/outdated-browser': ['outdatedBrowser'],
    '/profile/orders': ['orders', 'wholesaleOrders'],
    '/profile/orders/[orderNumber]': ['orderDetails']
  },
  logger: missingKeyLogger
};

function missingKeyLogger({ namespace, i18nKey }) {
  if (process.env.ENVIRONMENT === 'Production') {
    return;
  }

  // This means that instead of "ns:value", "value" has been misspelled (without namespace)
  if (!namespace) {
    console.warn(`[next-translate] The text "${i18nKey}" has no namespace in front of it.`);

    return;
  }

  console.warn(
    `[next-translate] "${namespace}:${i18nKey}" is missing in current namespace configuration. Try adding "${i18nKey}" to the namespace "${namespace}".`
  );
}

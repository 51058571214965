export const LOCALES_BY_CODE = {
  en: 'en',
  es: 'es',
  fr: 'fr'
} as const;

export type TLocale = keyof typeof LOCALES_BY_CODE;

export const LOCALES_BY_COUNTRY_CODE = {
  'en-GB': 'en-GB'
} as const;

export type TLocaleByCountry = keyof typeof LOCALES_BY_COUNTRY_CODE;

/** Локаль по-умолчанию (на случай ошибки определения локали) */
export const DEFAULT_LOCALE: TLocale = LOCALES_BY_CODE.en;

function checkIsValidLocale(locale: string): locale is TLocale {
  return LOCALES_BY_CODE[locale as TLocale] !== undefined;
}

function checkIsValidLocaleByCountry(locale: string): locale is TLocaleByCountry {
  return LOCALES_BY_COUNTRY_CODE[locale as TLocaleByCountry] !== undefined;
}

export function getResolvedLocale(locale: string): TLocale {
  return checkIsValidLocale(locale) ? locale : DEFAULT_LOCALE;
}

export type TContentByLocale<T> = { [K in TLocale]: T };

export function createGetContentByLocale<T>(content: TContentByLocale<T>) {
  return function (locale: string) {
    const resolvedLocale = getResolvedLocale(locale);
    const result = content[resolvedLocale] ?? content[DEFAULT_LOCALE];

    // Подразумевается, что контент для en локали точно должен быть, поэтому точно не null
    return result!;
  };
}

/** Получаем полное наименование локали (с учетом alpha2-кода страны) */
export function getLocaleByCountry(locale: string, countryCode?: string): TLocaleByCountry | TLocale {
  const resolvedLocale = getResolvedLocale(locale);

  if (resolvedLocale !== locale || !countryCode) {
    return resolvedLocale;
  }

  const localeByCountry = `${locale}-${countryCode.toLocaleUpperCase()}`;

  if (!checkIsValidLocaleByCountry(localeByCountry)) {
    return locale;
  }

  return localeByCountry;
}

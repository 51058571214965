import { parse as parseQueryString } from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Router from 'next/router';

import { parseAccessToken, removeAccessToken, setAccessToken } from '@store/auth/entities/accessToken';
import { removeRefreshToken, setRefreshToken } from '@store/auth/entities/refreshToken';
import authorizeService, { TAuthorizeDto } from '@store/auth/services/authorize';
import eventsManager from '@services/common/eventsManager';

export type TAuthStateInitialParams = {
  isUserAuthorized: boolean;
  userName: string;
};

export type TAuthState = TAuthStateInitialParams & {
  error: string;
  isPending: boolean;
};

export type TAuthDispatch = {
  /** Авторизация пользователя */
  authorize: (dto: TAuthorizeDto) => void;
  /** Удаление серверных ошибок */
  clearErrors: () => void;
  /** Разлогинивание пользвоателя */
  logout: () => void;
};

export default function useAuth(params: TAuthStateInitialParams): [TAuthState, TAuthDispatch] {
  const [state, setState] = useState<TAuthState>({
    ...params,
    error: '',
    isPending: false
  });

  useEffect(() => {
    // подписываемся на событие успешной авторизации
    eventsManager.subscribe('auth:success', (accessToken: string) => {
      const tokenData = parseAccessToken(accessToken);

      setState(prevState => ({
        ...prevState,
        isUserAuthorized: true,
        userName: tokenData.userName || tokenData.email
      }));
    });
  }, []);

  /** Авторизация пользователя */
  const authorize = useCallback((dto: TAuthorizeDto) => {
    setState(prevState => ({
      ...prevState,
      error: '',
      isPending: true
    }));

    authorizeService(dto)
      .then(({ access_token, refresh_token, token_type }) => {
        // записываем в куки токены
        setAccessToken(null, access_token, token_type);
        setRefreshToken(null, refresh_token);

        // получаем данные из токена доступа
        const tokenData = parseAccessToken(access_token);

        setState(prevState => ({
          ...prevState,
          isPending: false,
          isUserAuthorized: true,
          userName: tokenData.userName || tokenData.email
        }));

        if (window.location.search) {
          const queryParams = parseQueryString(window.location.search.slice(1));

          if (queryParams.returnUrl) {
            Router.push(decodeURIComponent(queryParams.returnUrl as string));
          }
        }
      })
      .catch(() => {
        setState(prevState => ({
          ...prevState,
          error: 'incorrect_email_or_password',
          isPending: false
        }));
      });
  }, []);

  /** Удаление серверных ошибок */
  const clearErrors = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      error: ''
    }));
  }, []);

  /** Разлогинивание пользователя */
  const logout = useCallback(() => {
    removeAccessToken();
    removeRefreshToken();

    window.location.reload();
  }, []);

  const authDispatch: TAuthDispatch = useMemo(
    () => ({
      authorize,
      clearErrors,
      logout
    }),
    [authorize, clearErrors, logout]
  );

  return [state, authDispatch];
}

import { useEffect } from 'react';
import dynamic from 'next/dynamic';

import { SIGN_IN_POPUP_NAME, SIGN_IN_QUERY } from '@store/auth/entities/signIn';
import { useAppDispatch, useAppState } from '@store/app';
import { useRouter } from 'next/router';

const LoginPopup = dynamic(() => import('./components/LoginPopup'));

const Authorization = (): JSX.Element | null => {
  const router = useRouter();

  const { activeModal } = useAppState();
  const { showModal } = useAppDispatch();

  useEffect(() => {
    if (router.query[SIGN_IN_QUERY] !== undefined && activeModal !== SIGN_IN_POPUP_NAME) {
      showModal(SIGN_IN_POPUP_NAME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return activeModal === SIGN_IN_POPUP_NAME ? <LoginPopup /> : null;
};

export default Authorization;

import { createContext, useContext, ReactNode } from 'react';

import useAuth, { TAuthDispatch, TAuthState, TAuthStateInitialParams } from './useAuth';

const AuthStateContext = createContext({} as TAuthState);
const AuthDispatchContext = createContext({} as TAuthDispatch);

type TAuthProvider = {
  children: ReactNode;
  value: TAuthStateInitialParams;
};

export const AuthProvider = ({ children, value }: TAuthProvider): JSX.Element => {
  const [state, dispatch] = useAuth(value);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export function useAuthState() {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}

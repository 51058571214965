import { AgentConfigOptions } from '@elastic/apm-rum';
import getConfig from 'next/config';
import packageInfo from '@/package.json';

export const APM_OPTIONS: AgentConfigOptions = {
  active: getConfig().publicRuntimeConfig.APM_USE,
  environment: getConfig().publicRuntimeConfig.APM_ENVIRONMENT,
  ignoreTransactions: [/.*_next.*/],
  serverUrl: getConfig().publicRuntimeConfig.APM_SERVER_URL,
  serviceName: getConfig().publicRuntimeConfig.APM_SERVICE_NAME,
  serviceVersion: packageInfo.version
};

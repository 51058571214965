import { Translate } from 'next-translate';

export const SIGN_IN_QUERY = 'signin-modal-open';
export const SIGN_IN_POPUP_NAME = 'signIn';

export const SIGN_IN_EMAIL_MAX_LENGTH = 50;
export const SIGN_IN_PASSWORD_MAX_LENGTH = 50;

/** Формирование текста ошибки формы авторизации */
export function getSignInError(key: string, type: string, t: Translate): string {
  if (!key || !type) {
    return '';
  }

  const errors: { [key: string]: { [key: string]: string } } = {
    login: {
      incorrectFormat: t('common:auth.errorEmailFormat'),
      required: t('common:auth.errorEmailRequired')
    },
    password: {
      required: t('common:auth.errorPasswordRequired')
    }
  };

  return errors[key] ? errors[key][type] : '';
}

/**
 * Формирование урла для редиректа на главную страницу с указанием того,
 * с какой страницы пришли, и параметром, отвечающим за открытие попапа авторизации
 */
export function prepareSignInRedirectUrl(path: string, query: string, returnUrl: string): string {
  const pathPart = `${path.replace(/\/$/, '')}/`;
  const returnUrlPath = encodeURIComponent(`${returnUrl.replace(/\/$/, '')}/${query ? `?${query}` : ''}`);

  return `${pathPart}?${SIGN_IN_QUERY}&returnUrl=${returnUrlPath}`;
}

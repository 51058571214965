/**
 * Корзина работает только на localStorage без хранения данных в памяти,
 * чтобы обработать ситуацию с синхронизацией вкладок браузера
 */
import { useCallback, useEffect, useState } from 'react';
import { v4 as guid } from 'uuid';
import useLocalStorage from 'react-use/lib/useLocalStorage';

import { TProduct } from '@entities/product';
import { TCartDelivery, TCartProduct, TCartState, TServerCartProduct } from '@entities/cart';
import {
  addProduct,
  changeProductItemCount,
  getActiveProductCount,
  getCartInitialState,
  isProductsCanBePurchased,
  markProductAsRemoved,
  removeProduct,
  restoreProduct,
  toggleAllProductsSelection,
  toggleProductSelection
} from '../entities/cart';

export type TCartDispatch = {
  addProductToCart: (product: TProduct, count: number) => void;
  changeCartProductItemCount: (productId: number, count: number) => void;
  getCartActiveProductsKeyValue: () => { [key: string]: number };
  getLastAddedProduct: () => TProduct | null;
  markCartProductAsRemoved: (productId: number) => void;
  removeCartProduct: (productId: number) => void;
  restoreCartProduct: (productId: number) => void;
  setCartProducts: (cartProducts: TCartProduct[]) => void;
  setLastAddedProduct: (product: TProduct | null) => void;
  toggleCartAllProductsSelection: (isSelected: boolean) => void;
  toggleCartProductSelection: (productId: number, isSelected: boolean) => void;
  updateCartAfterRevaluation: (
    cartProducts: TCartProduct[],
    items: TServerCartProduct[],
    delivery: TCartDelivery
  ) => TCartProduct[];
};

export default function useCart(): [TCartState, TCartDispatch] {
  /** Последний добавленный товар */
  const [lastAddedProduct, setLastAddedProduct] = useState<TProduct | null>(null);

  /** cartGuid нужен, чтобы понимать, были ли изменения корзины в других вкладках браузера */
  const [cartGuid, setCartGuid] = useState('');

  /** Состояние корзины из localStorage */
  const [cartState, setCartState] = useLocalStorage<TCartState>('cart', getCartInitialState());

  /** Получение состояния корзины */
  function getCartState(): TCartState {
    return cartState ? cartState : getCartInitialState();
  }

  /** Получение всех товаров из localStorage */
  function getProducts(): TCartProduct[] {
    return cartState ? cartState.products : [];
  }

  /** Хендлер обновления состояния корзины в localStorage */
  const onChangeCartEventHandler = useCallback(() => {
    const updatedCartState = JSON.parse(localStorage.getItem('cart') || '');

    if (updatedCartState && updatedCartState.guid !== cartGuid) {
      // если "добавленного товара" нет в списке товаров,
      // а на другой вкладке под корзиной показан тултип с тем же товаром, то "добавленный товар" удаляем
      if (
        lastAddedProduct &&
        !updatedCartState.products.find((p: TCartProduct) => p.current.productId === lastAddedProduct.productId)
      ) {
        setLastAddedProduct(null);
      }

      setCartGuid(updatedCartState.guid);
      setCartState(updatedCartState);
    }
  }, [cartGuid, setCartState, lastAddedProduct]);

  // подписываемся на изменение localStorage, которые могут произойти в другой вкладке
  useEffect(() => {
    window.addEventListener('storage', onChangeCartEventHandler);

    return () => window.removeEventListener('storage', onChangeCartEventHandler);
  }, [onChangeCartEventHandler]);

  return [
    getCartState(),
    {
      /** Добавление товара в корзину */
      addProductToCart(product, count) {
        const cartProducts = getProducts();
        const isNewProduct = cartProducts.findIndex(p => p.current.productId === product.productId) === -1;
        const updatedProducts = addProduct(cartProducts, product, count);

        setCartState({
          ...getCartState(),
          guid: guid(),
          isNeedRevaluate: true,
          isValid: false,
          itemCount: getActiveProductCount(updatedProducts),
          products: updatedProducts
        });

        if (isNewProduct) {
          setLastAddedProduct(product);
        }
      },

      /** Изменение количества айтемов товара, добавленного в корзину */
      changeCartProductItemCount(productId, count) {
        const cartProducts = getProducts();
        const product = cartProducts.find(cp => cp.current.productId === productId);

        if (product) {
          const updatedProducts = changeProductItemCount(cartProducts, productId, count);
          const newCartState: TCartState = {
            ...getCartState(),
            guid: guid(),
            products: updatedProducts
          };

          if (product.isSelected) {
            newCartState.isNeedRevaluate = true;
            newCartState.isValid = false;
            newCartState.itemCount = getActiveProductCount(updatedProducts);
          }

          setCartState(newCartState);
        }
      },

      /** Получение пар "productId: count" тех товаров, которые покупатель выбрал для покупки */
      getCartActiveProductsKeyValue() {
        const result: { [productId: string]: number } = {};
        const activeProducts = getProducts().filter(p => p.isSelected && !p.isRemoved);

        activeProducts.forEach(ap => {
          result[ap.current.productId] = ap.current.quantity;
        });

        return result;
      },

      /** Получение последнего добавленного товара, если таковой установлен */
      getLastAddedProduct() {
        return lastAddedProduct;
      },

      /** Помечаем товар как "удаленный" */
      markCartProductAsRemoved(productId) {
        const cartProducts = getProducts();
        const product = cartProducts.find(cp => cp.current.productId === productId);

        if (product) {
          const updatedProducts = markProductAsRemoved(cartProducts, productId);
          const newCartState: TCartState = {
            ...getCartState(),
            guid: guid(),
            itemCount: getActiveProductCount(updatedProducts),
            products: updatedProducts
          };

          if (product.isSelected) {
            newCartState.isNeedRevaluate = true;
            newCartState.isValid = false;
          }

          setCartState(newCartState);
        }
      },

      /** Удаляем товар из корзины навсегда */
      removeCartProduct(productId) {
        const updatedProducts = removeProduct(getProducts(), productId);

        setCartState({
          ...getCartState(),
          guid: guid(),
          products: updatedProducts
        });
      },

      /** Восстанавливаем товар из "удаленного" */
      restoreCartProduct(productId) {
        const cartProducts = getProducts();
        const product = cartProducts.find(p => p.current.productId === productId);

        if (product) {
          const updatedProducts = restoreProduct(getProducts(), productId);
          const newCartState: TCartState = {
            ...getCartState(),
            guid: guid(),
            itemCount: getActiveProductCount(updatedProducts),
            products: updatedProducts
          };

          if (product.isSelected) {
            newCartState.isNeedRevaluate = true;
            newCartState.isValid = false;
          }

          setCartState(newCartState);
        }
      },

      /** Перезапись товаров и доставки в localStorage без отметки о необходимости пересчитать корзину */
      setCartProducts(products) {
        setCartState({
          ...getCartState(),
          guid: guid(),
          itemCount: getActiveProductCount(products),
          products
        });
      },

      /** Проставляем/сбрасываем последний добавленный товар */
      setLastAddedProduct(product: TProduct | null) {
        if (
          ((product === null || lastAddedProduct === null) && product !== lastAddedProduct) ||
          (product !== null && lastAddedProduct !== null && product.productId !== lastAddedProduct.productId)
        ) {
          setLastAddedProduct(product);
        }
      },

      /** Выделение/Снятие выделения для всех товаров */
      toggleCartAllProductsSelection(isSelected) {
        const updatedProducts = toggleAllProductsSelection(getProducts(), isSelected);

        setCartState({
          ...getCartState(),
          guid: guid(),
          isNeedRevaluate: true,
          isValid: false,
          itemCount: getActiveProductCount(updatedProducts),
          products: updatedProducts
        });
      },

      /** Выделение/Снятие выделения c товара */
      toggleCartProductSelection(productId, isSelected) {
        const updatedProducts = toggleProductSelection(getProducts(), productId, isSelected);

        setCartState({
          ...getCartState(),
          guid: guid(),
          isNeedRevaluate: true,
          isValid: false,
          itemCount: getActiveProductCount(updatedProducts),
          products: updatedProducts
        });
      },

      /** Обновление корзины данными с бекенда */
      updateCartAfterRevaluation(cartProducts, items, delivery) {
        const updatedProducts = cartProducts.map(product => {
          const index = items.findIndex(i => product.current.productId === i.current.productId);

          return index === -1 ? product : { ...product, ...items[index] };
        });

        setCartState({
          ...getCartState(),
          delivery,
          guid: guid(),
          isNeedRevaluate: false,
          isValid: isProductsCanBePurchased(updatedProducts),
          itemCount: getActiveProductCount(updatedProducts),
          products: updatedProducts
        });

        return updatedProducts;
      }
    }
  ];
}

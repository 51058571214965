import { NextPageContext } from 'next';
import nookies from 'nookies';

import defineCountryByIp from '@store/app/services/defineCountryByIp';
import fetchDomains from '@store/app/services/fetchDomains';
import { getDomainFirstCountryByLocale, checkIsCountryValidForLocale, TDomains } from '@store/app/entities/domains';

import { COUNTRY_KEY } from '@constants/countries';
import { DEFAULT_LOCALE } from '@constants/locales';
import { SECONDS_IN_YEAR } from '@constants/time';

/** Метод сохранения страны в куки */
function saveCountryInCookie(ctx: NextPageContext, country: string): void {
  nookies.set(ctx, COUNTRY_KEY, country, {
    maxAge: SECONDS_IN_YEAR,
    path: '/'
  });
}

type TGetCountryInfoResponse = {
  /** Страна доставки (одна из стран домена) */
  country: string;
  /** Домены с локалями и списком стран */
  domains: TDomains;
  /** Флаг, нужно ли предложить пользователю подтвердить страну */
  isNeedConfirmCountry: boolean;
  /** Локаль домена (язык) */
  locale: string;
};

export default async function fetchAppData(ctx: NextPageContext): Promise<TGetCountryInfoResponse> {
  // Получаем домены со списками стран
  const data = await fetchDomains();
  const domains = data.domains || [];
  const { locale = DEFAULT_LOCALE, query, req } = ctx;

  // Если нет доменов, то возвращаем объект без страны
  if (!domains.length) {
    // TODO: возможно тут нужно редиректить на 500-ку
    return Promise.resolve({
      country: '',
      domains,
      isNeedConfirmCountry: false,
      locale: DEFAULT_LOCALE
    });
  }

  // Сценарий 1: проверяем, указана ли страна в query
  const queryCountry = query?.[COUNTRY_KEY] && typeof query[COUNTRY_KEY] === 'string' ? query[COUNTRY_KEY] : '';

  // Страна указана через query и соответствует локали. Подтверждение пользователем выбора страны не требуется
  if (queryCountry && checkIsCountryValidForLocale(domains, locale, queryCountry)) {
    saveCountryInCookie(ctx, queryCountry);

    return Promise.resolve({
      country: queryCountry,
      domains,
      isNeedConfirmCountry: false,
      locale
    });
  }

  // Сценарий 2: проверяем, была ли ранее определена страна (через куки)
  const cookiesCountry = nookies.get(ctx)[COUNTRY_KEY] || '';

  // Страна указана в куках и соответствует локали. Подтверждение пользователем выбора страны не требуется
  if (cookiesCountry && checkIsCountryValidForLocale(domains, locale, cookiesCountry)) {
    return Promise.resolve({
      country: cookiesCountry,
      domains,
      isNeedConfirmCountry: false,
      locale
    });
  }

  // Сценарий 3: определяем страну посредством обращения на сервер
  const headers = req?.headers || undefined;
  const countryData = await defineCountryByIp(headers);
  const countryCode = countryData.countryCode ? countryData.countryCode.toLowerCase() : '';

  // Страна определена на сервере и соответствует локали
  if (countryCode && checkIsCountryValidForLocale(domains, locale, countryCode)) {
    saveCountryInCookie(ctx, countryCode);

    return Promise.resolve({
      country: countryCode,
      domains,
      isNeedConfirmCountry: false,
      locale
    });
  }

  // Получаем данные из домена по локали. Предлагаем пользователю подтвердить страну
  return Promise.resolve({
    country: getDomainFirstCountryByLocale(domains, locale),
    domains,
    isNeedConfirmCountry: true,
    locale
  });
}

/**
 * Сервис обмена сообщениями
 */
type TChannel = {
  fn: Function;
  context: any;
};

function eventsManager() {
  let channels: { [key: string]: TChannel[] } = {};

  /** Подписка на событие канала */
  const subscribe = (channelName: string, handler: Function, context: any = null): void => {
    // если канала нет, создаем его
    if (!channels[channelName]) {
      channels[channelName] = [];
    }

    channels[channelName].push({
      fn: handler,
      context
    });
  };

  /** Отмена подписки на событие канала */
  const unSubscribe = (channelName: string, handler: Function): void => {
    const subscribers = channels[channelName];

    if (!subscribers) {
      return;
    }

    const subscriberIndex = subscribers.findIndex(item => item.fn === handler);

    if (subscriberIndex !== -1) {
      // если подписчик один, то удаляем канал
      if (subscribers.length === 1) {
        delete channels[channelName];
      } else {
        channels[channelName] = [...subscribers.slice(0, subscriberIndex), ...subscribers.slice(subscriberIndex + 1)];
      }
    }
  };

  /** Запуск события определнного канала */
  const trigger = (channelName: string, data: unknown | null = null) => {
    if (channels[channelName]) {
      channels[channelName].forEach(subscriber => {
        subscriber.fn.call(subscriber.context, data);
      });
    }
  };

  /** Очистка всех каналов */
  const clean = (): void => {
    channels = {};
  };

  return {
    clean,
    subscribe,
    trigger,
    unSubscribe
  };
}

export default eventsManager();

import React from 'react';

import Error from '@components/PageContents/Error';

import logError from '@services/common/logError';

type TErrorBoundaryProps = {
  children: React.ReactNode;
  errorContent?: React.ReactNode;
};

type TErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<TErrorBoundaryProps, TErrorBoundaryState> {
  constructor(props: TErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): TErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    logError(error);
  }

  render(): React.ReactNode {
    const { errorContent, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return errorContent || <Error />;
    }

    return children;
  }
}

export default ErrorBoundary;

import { getAllCountries, getCountriesByLocale, TDomains } from '@store/app/entities/domains';

export const SET_ACTIVE_MODAL = 'setActiveModal';
export const SET_COUNTRY = 'setCountry';
export const SET_COUNTRY_MODAL_VISIBILITY = 'setCountryModalVisibility';
export const TOGGLE_COUNTRY_MODAL_VISIBILITY = 'toggleCountryModal';

export type TAppState = {
  /** Активное модальное окно (одновременно может быть открыто только 1 окно) */
  activeModal: string;
  /** Дополнительные параметры активного модального окна */
  activeModalParams: { [key: string]: unknown } | null;
  /** Доступные страны доставки */
  allCountries: string[];
  /** Выбранная страна доставки */
  country: string;
  /** Страны доставки в рамках текущего домена */
  domainCountries: string[];
  /** Домены с инфой о языках и странах */
  domains: TDomains;
  /** Флаг, нужно ли подтвердить страну доставки */
  isNeedConfirmCountry: boolean;
  /** Флаг, открыто ли окно выбора страны доставки */
  isSelectCountryModalVisible: boolean;
};

export type TAppStateInitialParams = {
  country: string;
  domains: TDomains;
  isNeedConfirmCountry: boolean;
  locale: string;
};

type TAppAction =
  | {
      type: typeof SET_ACTIVE_MODAL;
      payload: {
        modalName: string;
        params?: { [key: string]: unknown };
      };
    }
  | { type: typeof SET_COUNTRY; payload: string }
  | { type: typeof SET_COUNTRY_MODAL_VISIBILITY; payload: boolean }
  | { type: typeof TOGGLE_COUNTRY_MODAL_VISIBILITY };

export function getDefaultState({ domains, country, isNeedConfirmCountry, locale }: TAppStateInitialParams): TAppState {
  return {
    activeModal: '',
    activeModalParams: null,
    allCountries: domains ? getAllCountries(domains) : [],
    country,
    domainCountries: domains ? getCountriesByLocale(domains, locale) : [],
    domains,
    isNeedConfirmCountry,
    isSelectCountryModalVisible: false
  };
}

export default function appReducer(state: TAppState, action: TAppAction): TAppState {
  const { type } = action;

  switch (type) {
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.payload.modalName,
        activeModalParams: action.payload?.params || null
      };

    case SET_COUNTRY:
      return { ...state, country: action.payload, isNeedConfirmCountry: false };

    case SET_COUNTRY_MODAL_VISIBILITY:
      return { ...state, isSelectCountryModalVisible: action.payload };

    case TOGGLE_COUNTRY_MODAL_VISIBILITY:
      return { ...state, isSelectCountryModalVisible: !state.isSelectCountryModalVisible };

    default: {
      throw new Error(`appReducer: unhandled action type: ${type}`);
    }
  }
}

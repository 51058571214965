import { DEFAULT_LOCALE } from '@constants/locales';

export type TDomain = {
  /** Поддерживаемые страны по стандарту Alpha2 ISO 3166-1 */
  countries: string[];
  /** Домен */
  domain: string;
  /** Язык/локаль домена по стандарту ISO 639-1 */
  language: string;
};

export type TDomains = TDomain[];

/** Сортировка стран (с использованием переводов на заданный язык) */
export function countrySorter(t: (key: string) => string) {
  return (a: string, b: string): number => {
    const translatedA = t(`common:countries.${a}`);
    const translatedB = t(`common:countries.${b}`);

    return translatedA.localeCompare(translatedB);
  };
}

/** Получение списка всех стран всех доменов */
export function getAllCountries(domains: TDomains): string[] {
  return domains.map(domain => domain.countries).reduce((a, b) => [...a, ...b], []);
}

/** Получение стран заданного домена */
export function getCountriesByLocale(domains: TDomains, locale: string): string[] {
  const domain = domains.find(item => item.language === locale);

  return domain?.countries ?? [];
}

/** Получение первой страны из списка стран указанной локали */
export function getDomainFirstCountryByLocale(domains: TDomains, locale: string): string {
  const domain = domains.find(item => item.language === locale);

  return domain?.countries?.[0] ?? '';
}

/** Получение локали по стране */
export function getLocaleByCountry(domains: TDomains, country: string): string {
  const domain = domains.find(item => item.countries.includes(country));

  return domain?.language ?? DEFAULT_LOCALE;
}

/** Соответствует ли страна локали */
export function checkIsCountryValidForLocale(domains: TDomains, locale: string, countryName: string): boolean {
  const domain = domains.find(item => item.language === locale);

  return domain?.countries.includes(countryName) ?? false;
}

import request from '@services/common/request';
import { TDomains } from '../entities/domains';

export type TFetchDomainsResponse = {
  domains: TDomains;
};

export default async function fetchDomains(): Promise<TFetchDomainsResponse> {
  try {
    return await request.get('/api/domains').then(res => res.data);
  } catch (error) {
    return { domains: [] };
  }
}

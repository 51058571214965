import NextLink from 'next/link';
import React, { ReactElement, forwardRef, Ref } from 'react';
import { Button as ButtonComponent, ButtonProps } from 'emex-ui-kit/dist/Button';
import { Link as LinkComponent, LinkProps } from 'emex-ui-kit/dist/Link';

export type TNextRouteProps<T> = T & {
  /** Адрес роута next.js, на который нужно перейти.
   * @param href - динамический роут
   * @param as - путь, который будет отображаться в строке URL браузера.
   * Используется для динамических маршрутов */
  to?:
    | string
    | {
        href: string;
        as: string;
      };
};

const LinkHocComponent = (
  { to, ...otherProps }: TNextRouteProps<LinkProps>,
  ref: Ref<HTMLAnchorElement>
): ReactElement => {
  if (to === undefined) {
    return <LinkComponent ref={ref} {...otherProps} />;
  }

  const nextLinkProps = typeof to === 'string' ? { href: to } : to;

  return (
    <NextLink {...nextLinkProps} passHref>
      <LinkComponent ref={ref} {...otherProps} />
    </NextLink>
  );
};

const ButtonHocComponent = (
  { to, ...otherProps }: TNextRouteProps<ButtonProps>,
  ref: Ref<HTMLButtonElement>
): ReactElement => {
  if (to === undefined) {
    return <ButtonComponent ref={ref} {...otherProps} />;
  }

  const nextLinkProps = typeof to === 'string' ? { href: to } : to;

  return (
    <NextLink {...nextLinkProps}>
      <ButtonComponent ref={ref} {...otherProps} />
    </NextLink>
  );
};

export const Button = forwardRef<HTMLButtonElement, TNextRouteProps<ButtonProps>>(ButtonHocComponent);
export const Link = forwardRef<HTMLAnchorElement, TNextRouteProps<LinkProps>>(LinkHocComponent);

import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';

type TTestClientErrorOnStagingProps = {
  children: ReactNode;
  queryParamForThrowingError: string;
};

const TestClientErrorOnStaging = ({
  children,
  queryParamForThrowingError
}: TTestClientErrorOnStagingProps): JSX.Element => {
  const router = useRouter();

  useEffect(() => {
    if (!getConfig()?.publicRuntimeConfig?.IS_PROD && router.query.hasOwnProperty(queryParamForThrowingError)) {
      // AUX: Вызов клиентской ошибки на тестовых стендов для проверки
      throw new Error(`Test client error for testing ${queryParamForThrowingError}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default TestClientErrorOnStaging;

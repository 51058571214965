import { createContext, useContext, ReactNode } from 'react';

import useApp, { TAppDispatch, TAppState, TAppStateInitialParams } from './useApp';

const AppStateContext = createContext({} as TAppState);
const AppDispatchContext = createContext({} as TAppDispatch);

type TAppProvider = {
  children: ReactNode;
  value: TAppStateInitialParams;
};

export const AppProvider = ({ children, value }: TAppProvider): JSX.Element => {
  const [state, dispatch] = useApp(value);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export function useAppState() {
  const context = useContext(AppStateContext);

  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }

  return context;
}

export function useAppDispatch() {
  const context = useContext(AppDispatchContext);

  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }

  return context;
}

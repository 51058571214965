import { AppContext } from 'next/app';
import Bowser from 'bowser';

import isBrowser from '@shared/isBrowser';
import isWebCrawler from '@shared/isWebCrawler';
import { VALID_BROWSER_LIST } from '@constants/browserList';

export const isValidBrowser = (userAgent: string): boolean => {
  try {
    if (isWebCrawler(userAgent)) return true;

    const { browser, os } = Bowser.parse(userAgent);

    return (
      VALID_BROWSER_LIST.filter(item => {
        const currentOs = os.name;
        const currentBrowserName = browser.name;
        const currentBrowserVersion = Number(browser.version?.split('.')[0]);

        return (
          item.name === currentBrowserName &&
          Number(item.version) <= currentBrowserVersion &&
          (!item.os || item.os.name === currentOs)
        );
      }).length > 0
    );
  } catch (e) {
    return true;
  }
};

const browserGuard = (appContext: AppContext): void => {
  const { ctx, router } = appContext;

  try {
    if (!isBrowser) {
      const userAgent = ctx.req?.headers['user-agent'] || '';
      const isOutdatedPage = router.pathname === '/outdated-browser';

      // если мы уже на странице браузеров, нет смысла делать проверку
      if (isOutdatedPage) {
        return;
      }

      if (!isValidBrowser(userAgent)) {
        ctx?.res?.writeHead(302, { Location: '/outdated-browser' });
        ctx?.res?.end();
      }
    }
  } catch (e) {
    console.error('BrowserGuard Error: ', e);
  }
};

export default browserGuard;

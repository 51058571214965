import { useMemo, useState } from 'react';

import {
  TNotificationEmexAuto,
  addNotificationToList,
  deleteNotificationById,
  getNotificationById,
  prepareNotification
} from '../entities/notification';

// TODO: ?? продумать прокидыване времени жизни нотификации из самой нотификации
const DESTROY_TIMEOUT = 10000;

export type TNotificationsDispatch = {
  createNotification: (notification: TNotificationEmexAuto) => void;
  destroyNotification: (id: number | string) => void;
};

export default function useNotifications(): [TNotificationEmexAuto[], TNotificationsDispatch] {
  const [notifications, setNotifications] = useState<TNotificationEmexAuto[]>([]);

  /** Удаление текущей нотификации */
  const destroyNotification = (id: number | string): void => {
    const currentNotification = getNotificationById(notifications, id);

    if (currentNotification?.timerId) {
      clearTimeout(currentNotification.timerId);
    }

    setNotifications(prevNotifications => deleteNotificationById(prevNotifications, id));
  };

  /** Создание новой нотификации */
  const createNotification = (notification: TNotificationEmexAuto): void => {
    // подготавливаем нотификацию (добавляем в нее недостающие параметры)
    const resultNotification = prepareNotification(notification);

    // запускаем таймер до удаления нотификации и сохраняем id таймера в нотификацию
    resultNotification.timerId = setTimeout(() => {
      destroyNotification(resultNotification.id || 0);
    }, DESTROY_TIMEOUT);

    // добавляем нотификацию в общий список нотификаций в нужном порядке
    setNotifications(prevNotifications => addNotificationToList(prevNotifications, resultNotification));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch: TNotificationsDispatch = useMemo(() => ({ createNotification, destroyNotification }), []);

  return [notifications, dispatch];
}

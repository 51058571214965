import getNextPublicConfig from 'next/config';
import { stringify } from 'qs';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import eventsManager from '@services/common/eventsManager';
const https = require('https');

import { EHttpErrorCodes } from '@entities/httpStatusCodes';
import { MILLISECONDS_IN_SECOND } from '@constants/time';
import { TError } from '@entities/errors';
import { createErrorGenerator, createIsError } from '@shared/errors';
import {
  getAccessToken,
  getAccessTokenType,
  removeAccessToken,
  setAccessToken
} from '@store/auth/entities/accessToken';
import { getRefreshToken, removeRefreshToken, setRefreshToken } from '@store/auth/entities/refreshToken';
import refreshTokenService from '@store/auth/services/refreshToken';

const nextPublicConfig = getNextPublicConfig();
const publicRuntimeConfig = nextPublicConfig?.publicRuntimeConfig || {};

const DEFAULT_ORIGIN = 'http://localhost:3000';
const CURRENT_ORIGIN = publicRuntimeConfig.ORIGIN || DEFAULT_ORIGIN;
const REQUEST_TIMEOUT = (publicRuntimeConfig?.IS_PROD ? 30 : 120) * MILLISECONDS_IN_SECOND;

const getOrigin = (): string => {
  if (typeof window !== 'undefined' && window.location.origin.indexOf('file://') === -1) {
    return window.location.origin;
  }

  return CURRENT_ORIGIN;
};

const http = axios.create({
  baseURL: getOrigin(),
  headers: {
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': getOrigin(),
    'Content-Type': 'application/json'
  },
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  }),
  paramsSerializer: (params: any) => stringify(params, { arrayFormat: 'repeat' }),
  timeout: REQUEST_TIMEOUT
});

/** Флаг, происходит ли сейчас запрос на обновление токена доступа */
let isSendingRefreshRequest = false;
/** Запрос на обновление токена доступа */
let updatingRefreshTokenPromise: Promise<void>;

/** Метод обновления токена */
const updateRefreshToken = async (refreshToken: string): Promise<void> => {
  try {
    isSendingRefreshRequest = true;

    const { access_token, refresh_token, token_type } = await refreshTokenService(refreshToken);

    // Записываем в куки токены
    setAccessToken(null, access_token, token_type);
    setRefreshToken(null, refresh_token);
    eventsManager.trigger('auth:success', access_token);
  } catch (e) {
    // Рефреш токена не удался, удаляем данные из кук
    removeRefreshToken();
    window.location.reload();
  } finally {
    isSendingRefreshRequest = false;
  }
};

export const REQUEST_ERROR_TYPE = 'REQUEST_ERROR';

export type TRequestErrorData = {
  /** Название кода ошибки */
  code: string;
  /** Данные с бэкенда */
  payload: unknown;
  /** Код статуса ошибки */
  status: number | null;
};

export type TRequestError = TError<TRequestErrorData, AxiosError>;

export const isRequestError = createIsError<TRequestError>(REQUEST_ERROR_TYPE);

export const isCanceledRequestError = (unknownError: unknown): unknownError is TRequestError => {
  const error = unknownError as TRequestError;

  return error?.data?.code === EHttpErrorCodes.CANCELED_REQUEST;
};

export const isNetworkError = (unknownError: unknown): unknownError is TRequestError => {
  const error = unknownError as TRequestError;

  return error?.data?.code === EHttpErrorCodes.NETWORK_ERROR;
};

export const createRequestError = createErrorGenerator<TRequestError>(REQUEST_ERROR_TYPE);

/** Получение текста ошибки по ее коду */
export function getRequestErrorTranslationKeyByCode(code: string): string {
  switch (code) {
    case EHttpErrorCodes.NETWORK_ERROR:
      return 'common:requestErrors.networkError';

    case EHttpErrorCodes.TIMEOUT_ERROR:
      return 'common:requestErrors.timeoutError';

    default:
      return 'common:requestErrors.unknownError';
  }
}

/** Приведение ошибки запроса к общей модели данных */
function parseRequestError(unknownError: any): TRequestError {
  if (axios.isAxiosError(unknownError)) {
    const axiosError = unknownError as AxiosError;

    return createRequestError({
      error: axiosError,
      data: {
        code: axiosError.code || EHttpErrorCodes.UNKNOWN_ERROR,
        payload: axiosError.response?.data || {},
        status: axiosError.response?.status || null
      }
    });
  }

  return unknownError;
}

http.interceptors.request.use(async (request: AxiosRequestConfig) => {
  const accessToken = getAccessToken();
  const accessTokenType = getAccessTokenType();

  // добавляем токен доступа, если таковой имеется
  if (accessToken && accessTokenType) {
    return {
      ...request,
      headers: {
        ...(request?.headers || {}),
        Authorization: `${accessTokenType} ${accessToken}`
      }
    };
  }

  return request;
});

http.interceptors.response.use(
  response => response,
  async error => {
    // если запрос упал по причине отсутствия авторизации
    if (error.response?.status === 401) {
      // Удаляем токен доступа из кук
      removeAccessToken();

      const originalConfig = error.config;
      const refreshToken = getRefreshToken();

      // если запрос упал после повторного запуска,
      // или нет возможности обновить токен доступа,
      // то перезагружаем страницу
      if (originalConfig._retry || !refreshToken) {
        window.location.reload();

        throw parseRequestError(error);
      }

      // отмечаем, что запрос будет произведен повторно
      originalConfig._retry = true;

      // пробуем перезапросить токен доступа
      if (!isSendingRefreshRequest) {
        updatingRefreshTokenPromise = updateRefreshToken(refreshToken);
      }

      await updatingRefreshTokenPromise;

      return http(originalConfig);
    }

    throw parseRequestError(error);
  }
);

const request = {
  get<T = any, R = AxiosResponse<T>>(url: string | undefined, params: object = {}, options: object = {}): Promise<R> {
    if (typeof url !== 'string') {
      throw new Error('Request url is not defined');
    }

    return http.get(url, { params, ...options });
  },

  post<T = any, R = AxiosResponse<T>>(
    url: string | undefined,
    body?: object,
    params: object = {},
    options: object = {}
  ): Promise<R> {
    if (typeof url !== 'string') {
      throw new Error('Request url is not defined');
    }

    return http.post(url, body, { params, ...options });
  },

  patch<T = any, R = AxiosResponse<T>>(
    url: string | undefined,
    body: object,
    params: object = {},
    options: object = {}
  ): Promise<R> {
    if (typeof url !== 'string') {
      throw new Error('Request url is not defined');
    }

    return http.patch(url, body, { params, ...options });
  },

  put<T = any, R = AxiosResponse<T>>(
    url: string | undefined,
    body: object,
    params: object = {},
    options: object = {}
  ): Promise<R> {
    if (typeof url !== 'string') {
      throw new Error('Request url is not defined');
    }

    return http.put(url, body, { params, ...options });
  },

  delete<T = any, R = AxiosResponse<T>>(
    url: string | undefined,
    params: object = {},
    options: object = {}
  ): Promise<R> {
    if (typeof url !== 'string') {
      throw new Error('Request url is not defined');
    }

    return http.delete(url, { params, ...options });
  }
};

export default request;

import { ReactNode, createContext, useContext } from 'react';

import { TNotification } from 'emex-ui-kit/dist/Notifications';
import useNotifications, { TNotificationsDispatch } from './useNotifications';

const NotificationsStateContext = createContext([] as TNotification[]);
const NotificationsDispatchContext = createContext({} as TNotificationsDispatch);

export const NotificationsProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, dispatch] = useNotifications();

  return (
    <NotificationsStateContext.Provider value={state}>
      <NotificationsDispatchContext.Provider value={dispatch}>{children}</NotificationsDispatchContext.Provider>
    </NotificationsStateContext.Provider>
  );
};

export function useNotificationsState() {
  const state = useContext(NotificationsStateContext);

  if (state === undefined) {
    throw new Error('useNotificationsState must be used within a NotificationsProvider');
  }

  return state;
}

export function useNotificationsDispatch() {
  const dispatch = useContext(NotificationsDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useNotificationsDispatch must be used within a NotificationsProvider');
  }

  return dispatch;
}

import { NextPageContext } from 'next';
import getNextPublicConfig from 'next/config';
import nookies from 'nookies';

import { SECONDS_IN_YEAR } from '@constants/time';

const nextPublicConfig = getNextPublicConfig();
const publicRuntimeConfig = nextPublicConfig?.publicRuntimeConfig || {};

export const API_AUTH_REFRESH_URL = publicRuntimeConfig.API_AUTH_URL || 'https://oauthtest.emexauto.com';
export const REFRESH_TOKEN_REQUEST_TIMEOUT = 7000;
const AUTH_REFRESH_TOKEN_CLIENT_ID = 'emexauto.front.dealer';
const AUTH_REFRESH_TOKEN_CLIENT_SECRET = 'emexauto.front.dealer.secret';
const COOKIE_REFRESH_TOKEN = 'auth.refreshToken';

/** Получение параметров для токена авторизации */
export function getParamsForRefreshToken(): URLSearchParams {
  const params = new URLSearchParams();

  params.append('client_id', AUTH_REFRESH_TOKEN_CLIENT_ID);
  params.append('client_secret', AUTH_REFRESH_TOKEN_CLIENT_SECRET);
  params.append('grant_type', 'refresh_token');

  return params;
}

/** Получение рефреш-токена */
export function getRefreshToken(ctx?: NextPageContext): string {
  return nookies.get(ctx || null)[COOKIE_REFRESH_TOKEN] || '';
}

/** Запись в куки рефреш-токена */
export function setRefreshToken(ctx: NextPageContext | null, token: string): void {
  nookies.set(ctx, COOKIE_REFRESH_TOKEN, token, { maxAge: SECONDS_IN_YEAR, path: '/' });
}

/** Удаление рефреш-токена из кук */
export function removeRefreshToken(ctx?: NextPageContext): void {
  nookies.destroy(ctx || null, COOKIE_REFRESH_TOKEN, { path: '/' });
}

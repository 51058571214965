import { NextPageContext } from 'next';
import getNextPublicConfig from 'next/config';
import nookies from 'nookies';

import { SECONDS_IN_DAY } from '@constants/time';

const nextPublicConfig = getNextPublicConfig();
const publicRuntimeConfig = nextPublicConfig?.publicRuntimeConfig || {};

export const API_AUTH_URL = publicRuntimeConfig.API_AUTH_URL || 'https://oauthtest.emexauto.com';
const AUTH_ACCESS_TOKEN_CLIENT_ID = 'emexauto.front.dealer';
const AUTH_ACCESS_TOKEN_CLIENT_SECRET = 'emexauto.front.dealer.secret';
const AUTH_ACCESS_TOKEN_SCOPE = 'openid profile offline_access emexauto.dealer';
const COOKIE_ACCESS_TOKEN = 'auth.accessToken';
const COOKIE_ACCESS_TOKEN_TYPE = 'auth.accessTokenType';

/** Получение параметров для токена авторизации */
export function getParamsForAccessToken(): URLSearchParams {
  const params = new URLSearchParams();

  params.append('client_id', AUTH_ACCESS_TOKEN_CLIENT_ID);
  params.append('client_secret', AUTH_ACCESS_TOKEN_CLIENT_SECRET);
  params.append('grant_type', 'password');
  params.append('scope', AUTH_ACCESS_TOKEN_SCOPE);

  return params;
}

/** Получение токена доступа */
export function getAccessToken(ctx?: NextPageContext): string {
  return nookies.get(ctx || null)[COOKIE_ACCESS_TOKEN] || '';
}

/** Получение типа токена доступа */
export function getAccessTokenType(ctx?: NextPageContext): string {
  return nookies.get(ctx || null)[COOKIE_ACCESS_TOKEN_TYPE] || '';
}

/** Получение данных из токена доступа */
export function parseAccessToken(token: string): { email: string; expired: number | null; userName: string } {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const tokenData = JSON.parse(
    decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    )
  );

  return {
    email: tokenData?.email || '',
    expired: tokenData?.exp ? tokenData.exp * 1000 : null,
    userName: tokenData?.name || ''
  };
}

/** Проверка, протух ли токен доступа */
export function isAccessTokenExpired(token: string | null): boolean {
  if (!token) {
    return true;
  }

  const { expired } = parseAccessToken(token);

  if (!expired) {
    return true;
  }

  return new Date().getTime() > expired;
}

/** Запись в куки токена доступа */
export function setAccessToken(ctx: NextPageContext | null, token: string, tokenType: string): void {
  const options = { maxAge: SECONDS_IN_DAY, path: '/' };

  nookies.set(ctx, COOKIE_ACCESS_TOKEN, token, options);
  nookies.set(ctx, COOKIE_ACCESS_TOKEN_TYPE, tokenType, options);
}

/** Удаление токена авторизации из кук */
export function removeAccessToken(ctx?: NextPageContext): void {
  nookies.destroy(ctx || null, COOKIE_ACCESS_TOKEN, { path: '/' });
  nookies.destroy(ctx || null, COOKIE_ACCESS_TOKEN_TYPE, { path: '/' });
}

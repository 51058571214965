import { ReactNode } from 'react';

import { Link } from '@components/NextRouteHOC';

import pageUrls from '@constants/pageUrls';

type TLogoWrapperProps = {
  children?: ReactNode;
};

const LogoWrapper = ({ children }: TLogoWrapperProps): JSX.Element => <Link to={pageUrls.home()}>{children}</Link>;

export default LogoWrapper;

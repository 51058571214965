import { Fragment, useEffect, FC } from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';

const NProgressBar: FC = () => {
  useEffect(() => {
    NProgress.configure({
      minimum: 0.2,
      easing: 'ease',
      speed: 300,
      trickleSpeed: 100
    });

    Router.events.on('routeChangeStart', (newRoute, { shallow }) => {
      if (shallow) {
        return;
      }

      const currentRoute = Router.asPath;

      if (newRoute !== currentRoute) {
        NProgress.configure({ parent: 'body' });
        NProgress.start();
      }
    });

    Router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    Router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, []);

  return <Fragment />;
};

export default NProgressBar;

import request from '@services/common/request';

export type TDefineCountryByIpResponse = {
  countryCode: string | null;
};

export default async function defineCountryByIp(headers: any): Promise<TDefineCountryByIpResponse> {
  try {
    return await request.get('/api/location', {}, { headers }).then(res => res.data);
  } catch (error) {
    return Promise.resolve({ countryCode: null });
  }
}

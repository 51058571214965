/** Описание корзины */
export type TCartState = {
  /** Данные о доставке товаров */
  delivery: TCartDelivery;
  /** Идентификатор последнего изменения данных */
  guid: string;
  /** Флаг, нужно ли валидировать корзину */
  isNeedRevaluate: boolean;
  /** Флаг, валидна ли корзина */
  isValid: boolean;
  /** Количество активных товаров в корзине */
  itemCount: number;
  /** Список товаров */
  products: TCartProduct[];
};

/** Описание провалидированного на сервере товара */
export type TServerCartProduct = {
  /** Актуальное состояние товара (после перепроценки) */
  actual: TCartProductDto | null;
  /** Текущее состояние товара (получено при добавлении товара в корзину) */
  current: TCartProductDto;
  /** Статус валидности товара */
  status: ECartProductStatus;
  /** Ошибки валидации товара */
  validationErrors: TCartProductValidationErrors | null;
};

/** Описание товара */
export type TCartProduct = TServerCartProduct & {
  /** Флаг, удален ли товар */
  isRemoved: boolean;
  /** Флаг, выбран ли товар к покупке */
  isSelected: boolean;
};

/** Описание данных товара  */
export type TCartProductDto = {
  /** Доступное для заказа количество товара */
  availableQuantity: number;
  /** Внутренний идентификатор прайса товара */
  offerLogo: string;
  /** Бренд товара */
  makerName: string;
  /** Количество товара в упаковке */
  packQuantity: number;
  /** Цена за единицу товара */
  price: number;
  /** Идентификатор товара */
  productId: number;
  /** Название товара */
  productName: string | null;
  /** Парт. номер товара */
  productNumber: string;
  /** Количество заказываемых единиц товара */
  quantity: number;
  /** Нормализованный url на бренд товара */
  urlMakerName: string;
};

/** Статус товара */
export enum ECartProductStatus {
  INVALID = 'Valid',
  OUT_OF_STOCK = 'OutOfStock',
  VALID = 'Valid'
}

/** Ошибки валидации товара */
export type TCartProductValidationErrors = {
  /** Цена изменилась */
  isPriceChanged?: boolean;
  /** Заказанное количество больше доступного */
  isQuantityExceeded?: boolean;
  /** Количество не кратно размеру упаковки */
  isQuantityNotMultipleOfPack?: boolean;
};

/** Описание доставки */
export type TCartDelivery = {
  actual: TDelivery | null;
  current: TDelivery | null;
  status: ECartDeliveryStatus;
};

/** Структура доставки */
export type TDelivery = {
  /** Цена доставки в долларах */
  cost: number;
  /** Дата доставки */
  date: string;
  /** Количество дней для доставки */
  days: number;
};

/** Статус валидации доставки */
export enum ECartDeliveryStatus {
  INVALID = 'Valid',
  NOT_AVAILABLE = 'NotAvailable',
  NO_DELIVERY = 'NoDelivery',
  VALID = 'Valid'
}

/** Структура оплаты */
export type TPayment = {
  /** Максимальная сумма, которую можно оплатить через платёжный шлюз */
  amountLimit: number;
  /** Стоимость обработки платежа, если пталёж возможно расчитать */
  paymentProcessingFee: number;
  /** Статус оплаты */
  status: EPaymentStatus;
  /** Сумма платежа со стоимостью обработки платежа */
  totalPrice: number;
};

/** Статус оплаты */
export enum EPaymentStatus {
  /** Достигнут предел суммы */
  AMOUNT_LIMIT_REACHED = 'AmountLimitReached',
  /** Оплата недоступна */
  NOT_AVAILABLE = 'NotAvailable',
  /** Можно оплачивать */
  VALID = 'Valid'
}

import { AgentConfigOptions, ApmBase, Transaction, init as initApm } from '@elastic/apm-rum';

export type TCustomContext = Record<string, any>;

export type TApmError = {
  error: Error;
  customContext?: TCustomContext;
};

export type TInitParams = {
  options: AgentConfigOptions;
  onTransactionEnd?: (transaction: Transaction) => void;
  onTransactionStart?: (transaction: Transaction) => void;
};

/** apm service for @elastic/apm-rum */
const apmService = () => {
  let apm: ApmBase | null = null;

  const init = ({ options, onTransactionEnd, onTransactionStart }: TInitParams) => {
    if (apm) {
      console.error('APM has already been initialized');
      return;
    }

    apm = initApm(options);

    if (onTransactionStart) {
      apm.observe('transaction:start', onTransactionStart);
    }

    if (onTransactionEnd) {
      apm.observe('transaction:end', onTransactionEnd);
    }

    console.log('APM has been initialized');
  };

  const setError = ({ error, customContext }: TApmError) => {
    if (!apm) {
      return;
    }

    if (customContext) {
      apm.setCustomContext({ customContext });
    }

    apm.captureError(error);

    if (customContext) {
      apm.setCustomContext({ customContext: {} });
    }
  };

  const getApm = () => {
    return apm;
  };

  return {
    getApm,
    init,
    setError
  };
};

export default apmService();

const pageUrls = {
  about: () => '/about/',
  aboutContacts: () => '/about/contacts/',
  aboutDelivery: () => '/about/delivery/',
  aboutPayments: () => '/about/payments/',
  aboutReturns: () => '/about/returns/',
  bulkSearch: () => '/bulk-search/',
  cart: () => '/cart/',
  cartCheckout: () => '/cart/checkout/',
  home: () => '/',
  orderDetails: (orderNumber: string) => `/profile/orders/${orderNumber}/`,
  orders: () => '/profile/orders/',
  productDetails: (productNumber: string, urlMakerName: string) => `/oem/${productNumber}/${urlMakerName}/`,
  searchProduct: (productNumber: string) => `/oem/${productNumber}/`
};

export default pageUrls;

/**
 * Глобальный объект-хранилище для шаринга данных на сервере между страницами
 */
type TStorageKey = string;
type TStorageValue = any;
type TStorage = { [key: TStorageKey]: TStorageValue };

let store: TStorage = {};

const serverStorage = {
  get: (key: TStorageKey): TStorageValue => {
    return store[key];
  },

  set: (key: TStorageKey, value: TStorageValue): void => {
    store = { ...store, [key]: value };
  }
};

export default serverStorage;

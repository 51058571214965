import nextListWithIndex from '@shared/nextListWithIndex';
import { ECartDeliveryStatus, ECartProductStatus, TCartDelivery, TCartProduct, TCartState } from '@entities/cart';
import { TProduct } from '@entities/product';

/** Первичное создание товара */
export function createProduct(product: TProduct, quantity: number): TCartProduct {
  const [{ offerLogo, packQuantity, price, quantity: availableQuantity }] = product.offers;

  return {
    actual: null,
    current: {
      availableQuantity,
      offerLogo,
      makerName: product.makerName,
      packQuantity,
      price,
      productId: product.productId,
      productName: product.name,
      productNumber: product.number,
      quantity,
      urlMakerName: product.urlMakerName
    },
    isRemoved: false,
    isSelected: true,
    status: ECartProductStatus.VALID,
    validationErrors: null
  };
}

/** Добавление товара в список уже имеющихся товаров */
export function addProduct(cartProducts: TCartProduct[], product: TProduct, count: number): TCartProduct[] {
  const [{ offerLogo, packQuantity, price, quantity }] = product.offers;
  const index = cartProducts.findIndex(cp => cp.current.productId === product.productId);

  // если товара еще нет в списке, то добавляем его
  if (index === -1) {
    return [...cartProducts, createProduct(product, count)];
  }

  // товар есть в списке - обновляем его и делаем "активным"
  return nextListWithIndex(cartProducts, index, {
    actual: null,
    current: {
      ...cartProducts[index].current,
      availableQuantity: quantity,
      offerLogo,
      packQuantity,
      price,
      quantity: count
    },
    isRemoved: false,
    isSelected: true,
    status: ECartProductStatus.VALID,
    validationErrors: null
  });
}

/** Изменение количества айтемов товара в списке товаров */
export function changeProductItemCount(
  cartProducts: TCartProduct[],
  productId: number,
  productCount: number
): TCartProduct[] {
  // если количество товара = 0, то удаляем товар из списка
  if (productCount === 0) {
    return removeProduct(cartProducts, productId);
  }

  const index = cartProducts.findIndex(p => p.current.productId === productId);

  // если товар не найден в списке, возвращаем исходный массив товаров
  if (index === -1) {
    return cartProducts;
  }

  const { actual, current } = cartProducts[index];

  /** Скорректированное значение количества товаров */
  const correctedProductCount = getCorrectedProductCount({
    availableQuantity: actual ? actual.availableQuantity : current.availableQuantity,
    packQuantity: actual ? actual.packQuantity : current.packQuantity,
    prevProductCount: current.quantity,
    productCount
  });

  // меняем количество айтемов добавленного товара в списке
  return nextListWithIndex(cartProducts, index, {
    actual: actual ? { ...actual, quantity: correctedProductCount } : null,
    current: { ...current, quantity: correctedProductCount },
    status: ECartProductStatus.VALID,
    validationErrors: null
  });
}

/** Получение количества активных товаров в списке */
export function getActiveProductCount(cartProducts: TCartProduct[]): number {
  let count = 0;

  cartProducts
    .filter(cp => cp.isSelected && !cp.isRemoved && cp.status !== ECartProductStatus.OUT_OF_STOCK)
    .forEach(cp => {
      count += cp.current.quantity;
    });

  return count;
}

/** Получение дефолтного стэйта корзины */
export function getCartInitialState(): TCartState {
  return {
    delivery: getDeliveryInitialState(),
    guid: '',
    isNeedRevaluate: true,
    isValid: false,
    itemCount: 0,
    products: []
  };
}

/** Получение корректного количества тововаров (спомогательная функция для метода "Изменение количества айтемов товара в списке товаров")  */
export function getCorrectedProductCount({
  availableQuantity,
  packQuantity,
  prevProductCount,
  productCount
}: {
  /** Доступное количество товара */
  availableQuantity: number;
  /** Кратность упаковки товара */
  packQuantity: number;
  /** Предыдущее количества товаров */
  prevProductCount: number;
  /** Заданное количество товара */
  productCount: number;
}): number {
  // если в упаковке 1 товар
  if (packQuantity === 1) {
    // возвращаем заданное количество товара (если оно не превышает допустимое количество товара),
    // либо допустимое количество товара
    return Math.min(productCount, availableQuantity);
  } else {
    // в упаковне несколько товаров

    /** Максимально допустимое количество товара, которое можно купить (кратное упаковке) */
    const maxCountToBuy = Math.floor(availableQuantity / packQuantity) * packQuantity;

    // если товаров больше или равно максимально возможному количеству товара (кратно упаковке)
    if (productCount >= maxCountToBuy) {
      return maxCountToBuy;
    }

    /** Флаг, кратно ли заданное количество товаров количеству товаров в упаковке */
    const isPackCorrected = isCartQuantityCorrectedWithPack(packQuantity, productCount);
    /** Флаг, кратно ли предыдущее количество товаров количеству товаров в упаковке */
    const isPrevPackCorrected = isCartQuantityCorrectedWithPack(packQuantity, prevProductCount);

    // если заданное количество товаров кратно упаковке, то возвращаем возвращаем заданное количество товаров
    if (isPackCorrected && isPrevPackCorrected) {
      return productCount;
    }

    // разница в количестве товаров относительно смещения в большую или меньшую сторону
    const countOffset = prevProductCount < productCount ? packQuantity : 0;

    return (
      Math.floor((isPrevPackCorrected ? productCount : prevProductCount) / packQuantity) * packQuantity + countOffset
    );
  }
}

/** Получение дефолтного состояния доставки */
export function getDeliveryInitialState(): TCartDelivery {
  return {
    actual: null,
    current: null,
    status: ECartDeliveryStatus.NOT_AVAILABLE
  };
}

/** Изменение параметров товара */
function changeProductParams(
  cartProducts: TCartProduct[],
  productId: number,
  params: { [key: string]: any }
): TCartProduct[] {
  const index = cartProducts.findIndex(cp => cp.current.productId === productId);

  return index === -1 ? cartProducts : nextListWithIndex(cartProducts, index, params);
}

/** Получение общей цены выбранных товаров (до проценки и после) */
export function getProductsTotalPrice(cartProducts: TCartProduct[]): { newPrice: number; oldPrice: number } {
  let newPrice = 0;
  let oldPrice = 0;

  cartProducts.forEach(product => {
    if (product.isSelected && !product.isRemoved && product.status !== ECartProductStatus.OUT_OF_STOCK) {
      const currentPrice = product.current.quantity * product.current.price;

      oldPrice += currentPrice;
      newPrice += product.actual ? product.current.quantity * product.actual.price : currentPrice;
    }
  });

  return { newPrice, oldPrice };
}

/** Проверка корректности количества добавленных в корзину товаров количеству товаров в упаковке  */
export function isCartQuantityCorrectedWithPack(packQuantity: number, cartQuantity: number): boolean {
  if (packQuantity === 1) {
    return true;
  }

  return Number.isInteger(cartQuantity / packQuantity);
}

/** Проверка, можно ли все заданные товары купить (т.е. пойти дальше в чекаут) */
export function isProductsCanBePurchased(cartProducts: TCartProduct[]): boolean {
  const activeProducts = cartProducts.filter(
    cp => cp.isSelected && !cp.isRemoved && cp.status !== ECartProductStatus.OUT_OF_STOCK
  );

  if (!activeProducts.length) {
    return false;
  }

  return (
    activeProducts.filter(({ validationErrors }) => {
      return (
        validationErrors !== null &&
        (validationErrors.isQuantityExceeded || validationErrors.isQuantityNotMultipleOfPack)
      );
    }).length === 0
  );
}

/** Помечаем товар как "удаленный" */
export function markProductAsRemoved(cartProducts: TCartProduct[], productId: number): TCartProduct[] {
  return changeProductParams(cartProducts, productId, { isRemoved: true });
}

/** Удаляем товар навсегда */
export function removeProduct(cartProducts: TCartProduct[], productId: number): TCartProduct[] {
  const index = cartProducts.findIndex(cp => cp.current.productId === productId);

  return index === -1 ? cartProducts : [...cartProducts.slice(0, index), ...cartProducts.slice(index + 1)];
}

/** Восстанавливаем товар из "удаленного" */
export function restoreProduct(cartProducts: TCartProduct[], productId: number): TCartProduct[] {
  return changeProductParams(cartProducts, productId, { isRemoved: false });
}

/** Выделение/Снятие выделения для всех товаров */
export function toggleAllProductsSelection(cartProducts: TCartProduct[], isSelected: boolean): TCartProduct[] {
  return cartProducts.map(product => {
    if (!product.isRemoved && product.status !== ECartProductStatus.OUT_OF_STOCK) {
      return { ...product, isSelected };
    }

    return product;
  });
}

/** Выделение/Снятие выделения c товара */
export function toggleProductSelection(
  cartProducts: TCartProduct[],
  productId: number,
  isSelected: boolean
): TCartProduct[] {
  return changeProductParams(cartProducts, productId, { isSelected });
}

/** Обновление текущего состояния доставки (current) посредством вливания данных из актуального состояния (actual) */
export function updateDeliveryCurrentStateByActual(delivery: TCartDelivery): TCartDelivery {
  const { actual } = delivery;

  if (actual === null) {
    return delivery;
  }

  return {
    ...delivery,
    actual: null,
    current: actual
  };
}

/** Обновление текущего состояния товара (current) посредством вливания данных из актуального состояния (actual) */
export function updateProductsCurrentStateByActual(cartProducts: TCartProduct[]): TCartProduct[] {
  return cartProducts.map(product => {
    const { actual } = product;

    if (actual === null) {
      return product;
    }

    return {
      ...product,
      actual: null,
      current: actual,
      validationErrors: null
    };
  });
}

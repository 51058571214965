import { TError } from '@entities/errors';

/** Создает функцию вида isErrorCustomErrorType */
export function createIsError<T>(errorType: string) {
  return function (unknownError: unknown): unknownError is T {
    const error = unknownError as TError;

    return error?.type === errorType;
  };
}

/** Создает функцию виду createCustomErrorType */
export function createErrorGenerator<T extends TError>(errorType: string) {
  return function ({ data, error }: Omit<T, 'type'>) {
    return {
      data,
      error,
      type: errorType
    };
  };
}

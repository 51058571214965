import Script from 'next/script';
import getConfig from 'next/config';

const GA_TRACKING_ID_FOR_TEST = 'G-CMP2WSHY1D';
const GA_TRACKING_ID_FOR_PRODUCTION = 'G-VBESNZFDGY';

const GA_TRACKING_ID = getConfig()?.publicRuntimeConfig?.IS_PROD
  ? GA_TRACKING_ID_FOR_PRODUCTION
  : GA_TRACKING_ID_FOR_TEST;

const GoogleTag = (): JSX.Element => (
  <>
    {/* Global Site Tag (gtag.js) - Google Analytics */}
    <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
    <Script
      id="gtag-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GA_TRACKING_ID}');
          `
      }}
    />
  </>
);

export default GoogleTag;

import axios from 'axios';

import {
  API_AUTH_REFRESH_URL,
  REFRESH_TOKEN_REQUEST_TIMEOUT,
  getParamsForRefreshToken
} from '@store/auth/entities/refreshToken';

type TRefreshTokenResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
};

export default async function refreshToken(refreshTokenString: string): Promise<TRefreshTokenResponse> {
  const params = getParamsForRefreshToken();

  params.append('refresh_token', refreshTokenString);

  return axios
    .post(`${API_AUTH_REFRESH_URL}/connect/token`, params, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      timeout: REFRESH_TOKEN_REQUEST_TIMEOUT
    })
    .then(res => res.data);
}

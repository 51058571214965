import axios from 'axios';
import { API_AUTH_URL, getParamsForAccessToken } from '@store/auth/entities/accessToken';

export type TAuthorizeDto = {
  login: string;
  password: string;
};

type TAuthorizeResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
};

export default async function authorize(dto: TAuthorizeDto): Promise<TAuthorizeResponse> {
  const params = getParamsForAccessToken();

  params.append('username', dto.login);
  params.append('password', dto.password);

  return axios
    .post(`${API_AUTH_URL}/connect/token`, params, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
    .then(res => res.data);
}

import { v4 as guid } from 'uuid';
import { ENotificationPosition, ENotificationStatus, TNotification } from 'emex-ui-kit/dist/Notifications';

export type TNotificationEmexAuto = TNotification & {
  timerId?: NodeJS.Timeout;
};

/** Добавление нотификации в общий список нотификаций (в зависимости от позиционирования) */
export function addNotificationToList(
  notifications: TNotificationEmexAuto[],
  notification: TNotificationEmexAuto
): TNotificationEmexAuto[] {
  if (
    notification.position === ENotificationPosition.BOTTOM_LEFT ||
    notification.position === ENotificationPosition.BOTTOM_CENTER ||
    notification.position === ENotificationPosition.BOTTOM_RIGHT
  ) {
    return [notification, ...notifications];
  }

  return [...notifications, notification];
}

/** Удаление нотифкации по id */
export function deleteNotificationById(
  notifications: TNotificationEmexAuto[],
  id: number | string
): TNotificationEmexAuto[] {
  return notifications.filter(item => item.id !== id);
}

/** Получение нотифкации по id */
export function getNotificationById(
  notifications: TNotificationEmexAuto[],
  id: number | string
): TNotificationEmexAuto | undefined {
  return notifications.find(item => item.id === id);
}

/** Подготовка нотификации (добавляем в нее недостающие параметры) */
export function prepareNotification(notification: TNotificationEmexAuto): TNotificationEmexAuto {
  return {
    ...notification,
    id: notification.id || guid(),
    position: notification.position || ENotificationPosition.BOTTOM_CENTER,
    status: notification.status || ENotificationStatus.ERROR
  };
}

import { ReactNode, memo } from 'react';
import cn from 'classnames';

import { Button, ButtonSize, ButtonTheme } from '@components/NextRouteHOC';
import NotFoundIcon from '@static/icons/notFound.svg';
import styles from './Empty.module.scss';

export type TEmptyProps = {
  buttonSize?: ButtonSize;
  buttonText?: ReactNode;
  buttonUrl?: string;
  className?: string;
  description?: ReactNode;
  heading: ReactNode;
  headingClassName?: string;
  iconContent?: ReactNode;
  isButtonDisabled?: boolean;
  onButtonClick?: () => void;
};

const Empty = ({
  buttonSize = ButtonSize.MEDIUM,
  buttonText = '',
  buttonUrl,
  className = '',
  description = '',
  heading,
  headingClassName = '',
  iconContent = null,
  isButtonDisabled,
  onButtonClick
}: TEmptyProps): JSX.Element => (
  <div className={cn(styles.component, className)}>
    {iconContent ? iconContent : <NotFoundIcon className={styles.notFoundIcon} width={48} height={48} />}

    <div className={cn(styles.heading, headingClassName)}>{heading}</div>

    {description ? <div className={styles.description}>{description}</div> : null}

    {buttonText && (buttonUrl || onButtonClick) && (
      <Button
        className={styles.button}
        size={buttonSize}
        theme={ButtonTheme.ORANGE}
        to={buttonUrl}
        isDisabled={isButtonDisabled}
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
    )}
  </div>
);

export default memo(Empty);

export const COUNTRY_KEY = 'country';

/** Список альфа-2 обозначений известных стран */
export const countries = [
  'ae',
  'am',
  'au',
  'az',
  'eg',
  'ge',
  'hn',
  'id',
  'in',
  'ke',
  'kg',
  'ma',
  'ng',
  'pk',
  'pl',
  'sa',
  'sg',
  'tm',
  'tr',
  'us',
  'vn'
];

/** Список популярных стран (альфа-2) */
export const popularCountries = ['ae', 'au', 'eg', 'in', 'pk', 'sa', 'sg', 'us', 'vn'];

import { setCookie } from 'nookies';
import { useCallback, useReducer } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import appReducer, {
  TAppState,
  TAppStateInitialParams,
  getDefaultState,
  SET_ACTIVE_MODAL,
  SET_COUNTRY,
  SET_COUNTRY_MODAL_VISIBILITY,
  TOGGLE_COUNTRY_MODAL_VISIBILITY
} from './appReducer';
import { getLocaleByCountry } from '@store/app/entities/domains';

import { COUNTRY_KEY } from '@constants/countries';
import { SECONDS_IN_YEAR } from '@constants/time';
import cleanUrl from '@shared/cleanUrl';

export type { TAppState, TAppStateInitialParams };

type THideModalDispatch = () => void;
type TSetCountryDispatch = (newCountry: string) => void;
type TSetCountryModalVisibilityDispatch = (isVisible: boolean) => void;
type TShowModalDispatch = (modalName: string, params?: { [key: string]: unknown }) => void;
type TToggleCountryModalDispatch = () => void;

export type TAppDispatch = {
  hideModal: THideModalDispatch;
  setCountry: TSetCountryDispatch;
  setCountryModalVisibility: TSetCountryModalVisibilityDispatch;
  showModal: TShowModalDispatch;
  toggleCountryModal: TToggleCountryModalDispatch;
};

/** Метод сохранения страны в куки */
function saveCountryInCookie(newCountry: string): void {
  setCookie(null, COUNTRY_KEY, newCountry, {
    maxAge: SECONDS_IN_YEAR,
    path: '/'
  });
}

export default function useApp(params: TAppStateInitialParams): [TAppState, TAppDispatch] {
  const [state, dispatch] = useReducer(appReducer, getDefaultState(params));
  const router = useRouter();
  const { lang: currentLocale } = useTranslation();

  /** Показ модального окна */
  const showModal: TShowModalDispatch = useCallback(
    (modalName, modalParams) => {
      dispatch({
        type: SET_ACTIVE_MODAL,
        payload: { modalName, params: modalParams }
      });
    },
    [dispatch]
  );

  /** Сокрытие модального окна */
  const hideModal: THideModalDispatch = useCallback(() => {
    dispatch({
      type: SET_ACTIVE_MODAL,
      payload: { modalName: '' }
    });
  }, [dispatch]);

  /** Установка выбранной страны */
  const setCountry: TSetCountryDispatch = useCallback(
    newCountry => {
      const newLocale = getLocaleByCountry(state.domains, newCountry);

      if (newLocale !== currentLocale) {
        const newUrl = new URL(window.location.href);

        if (window.location.hostname === 'localhost') {
          saveCountryInCookie(newCountry);
          dispatch({ type: SET_COUNTRY, payload: newCountry });
        } else {
          newUrl.searchParams.set(COUNTRY_KEY, newCountry);
        }

        router.push(
          router.pathname,
          { pathname: cleanUrl(router.asPath), query: newUrl.searchParams.toString() },
          { locale: newLocale }
        );

        return;
      }

      saveCountryInCookie(newCountry);
      dispatch({ type: SET_COUNTRY, payload: newCountry });
    },
    [currentLocale, state.domains, router]
  );

  /** Показ/сокрытие попапа выбора страны */
  const setCountryModalVisibility: TSetCountryModalVisibilityDispatch = useCallback(
    isVisible => {
      dispatch({ type: SET_COUNTRY_MODAL_VISIBILITY, payload: isVisible });
    },
    [dispatch]
  );

  const toggleCountryModal: TToggleCountryModalDispatch = useCallback(() => {
    dispatch({ type: TOGGLE_COUNTRY_MODAL_VISIBILITY });
  }, [dispatch]);

  return [
    state,
    {
      hideModal,
      setCountry,
      setCountryModalVisibility,
      showModal,
      toggleCountryModal
    }
  ];
}

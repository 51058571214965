import { NextPageContext } from 'next';
import getConfig from 'next/config';

export default function testServerErrorOnStaging(ctx: NextPageContext): void {
  if (
    !getConfig()?.publicRuntimeConfig?.IS_PROD &&
    ctx.pathname === '/' &&
    ctx.query.hasOwnProperty('testServerError')
  ) {
    throw new Error('Test error for testing server error');
  }
}

import { NextPageContext } from 'next';

import {
  getAccessToken,
  getAccessTokenType,
  isAccessTokenExpired,
  parseAccessToken,
  removeAccessToken,
  setAccessToken
} from '@store/auth/entities/accessToken';
import { getRefreshToken, removeRefreshToken, setRefreshToken } from '@store/auth/entities/refreshToken';
import refreshTokenService from '@store/auth/services/refreshToken';

type TUserDetails = {
  /** Флаг, авторизован ли пользователь */
  isUserAuthorized: boolean;
  /** Имя пользователя */
  userName: string;
};

/** Получение данных о пользователе */
export default async function getUserDetails(ctx: NextPageContext): Promise<TUserDetails> {
  /** Данные неавторизованного пользователя */
  const notAuthorizedDetails: TUserDetails = {
    isUserAuthorized: false,
    userName: ''
  };

  const accessToken = getAccessToken(ctx);
  const accessTokenType = getAccessTokenType(ctx);

  // Если токена доступа нет, то пользователь не авторизован
  if (!accessToken || !accessTokenType) {
    // Пользователь не залогинен
    return Promise.resolve(notAuthorizedDetails);
  }

  // Если токен доступа есть и он не протух, то можно его использовать
  if (!isAccessTokenExpired(accessToken)) {
    const tokenData = parseAccessToken(accessToken);

    return Promise.resolve({
      isUserAuthorized: true,
      userName: tokenData.userName || tokenData.email
    });
  }

  // Токен доступа протух, удаляем его из кук
  removeAccessToken(ctx);

  const refreshToken = getRefreshToken(ctx);

  // Если есть рефреш-токен, то пробуем обновить токен доступа
  if (refreshToken) {
    try {
      const { access_token, refresh_token, token_type } = await refreshTokenService(refreshToken);

      // Записываем в куки токены
      setAccessToken(ctx, access_token, token_type);
      setRefreshToken(ctx, refresh_token);

      const tokenData = parseAccessToken(access_token);

      return Promise.resolve({
        isUserAuthorized: true,
        userName: tokenData.userName || tokenData.email
      });
    } catch (e) {
      // Рефреш токена не удался, удаляем данные из кук
      removeRefreshToken(ctx);
    }
  }

  // Пользователь не авторизован
  return Promise.resolve(notAuthorizedDetails);
}

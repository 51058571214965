import { useEffect, useState } from 'react';

import { Notifications } from 'emex-ui-kit/dist/Notifications';
import { useNotificationsDispatch, useNotificationsState } from '@store/notifications';

const NotificationsWithLogic = (): JSX.Element | null => {
  const [isInitialized, setIsInitialized] = useState(false);

  const notifications = useNotificationsState();
  const { destroyNotification } = useNotificationsDispatch();

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const onCloseClickHandler = (id: number | string): void => {
    destroyNotification(id);
  };

  return isInitialized ? (
    <Notifications notifications={notifications} onCloseClickCallback={onCloseClickHandler} />
  ) : null;
};

export default NotificationsWithLogic;
